.images-well {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 0px);

  &.asian-womxn .targetClass:hover:not(.poster),
  .targetClass:active:not(poster) {
    -webkit-box-shadow: 0px 0px 24px 12px #ff4c24;
    box-shadow: 0px 0px 24px 12px #ff4c24;
  }
  &.illustrators .targetClass:hover:not(.poster),
  .targetClass:active:not(poster) {
    -webkit-box-shadow: 0px 0px 24px 12px #ffceb3;
    box-shadow: 0px 0px 24px 12px #ffceb3;

    &.drop-shadow {
      -webkit-box-shadow: 0px 0px 0px 0px #ffceb3;
      box-shadow: 0px 0px 0px 0px #ffceb3;
      filter: drop-shadow(0 0 17px #ffceb3);
    }
  }
  &.blog .targetClass:hover:not(.poster),
  .targetClass:active:not(poster) {
    -webkit-box-shadow: 0px 0px 24px 12px #c8ffce;
    box-shadow: 0px 0px 24px 12px #c8ffce;
  }
  &.health .targetClass:hover:not(.poster),
  .targetClass:active:not(poster) {
    -webkit-box-shadow: 0px 0px 24px 12px #34ac5d;
    box-shadow: 0px 0px 24px 12px #34ac5d;
  }
  &.nightsnack .targetClass:hover:not(.poster),
  .targetClass:active:not(poster) {
    -webkit-box-shadow: 0px 0px 24px 12px #8667ff;
    box-shadow: 0px 0px 24px 12px #8667ff;
  }
  &.spiritual .targetClass:hover:not(.poster),
  .targetClass:active:not(poster) {
    -webkit-box-shadow: 0px 0px 24px 12px #0b8639;
    box-shadow: 0px 0px 24px 12px #0b8639;
  }

  &.asian-womxn .project-title {
    color: #ff4c24;
  }
  &.illustrators .project-title {
    color: #0b1676;
  }
  &.blog .project-title {
    color: #0a4d4d;
  }
  &.health .project-title {
    color: #34ac5d;
  }
  &.nightsnack .project-title {
    color: #8667ff;
  }
  &.spiritual .project-title {
    color: #0b8639;
  }
  .images-palette {
    width: 100vw;
    height: 100vh;
  }

  .targetClass {
    position: absolute;
    display: block;
    transform: translate(-50%, -50%);
    transition: 0.75s ease-out;
    z-index: 5;
    height: auto;

    img {
      max-width: 100%;
      max-height: 100%;
      position: relative;
    }

    .project-title {
      display: none;
      font-family: "Helvetica Roman";
      letter-spacing: -0.4px;
      line-height: 92%;
      text-transform: uppercase;
      min-width: 300px;
      min-height: 50px;
      text-align: center;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 999999999999999999999999999999999;
    }
    .test-relative {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &:hover:not(.poster) {
      z-index: 99999999;
      cursor: pointer;
      transition: 0.3s ease-out;
      transform: translate(-50%, -50%) scale(1.25);

      .project-title {
        display: block;

        @include respond-max($bp-tab) {
          display: none;
        }
      }
    }

    &.poster {
      left: 50%;
      z-index: 999;
      top: 55%;
      transform: translate(-50%, -50%);
      height: 940px;

      @include respond-max($bp-lg-desk) {
        height: 600px;
      }
      @include respond-max($bp-desk) {
        height: 600px;
      }
      @include respond-max($bp-lg-lap) {
        height: 520px;
      }
      @include respond-max($bp-lap) {
        height: 435px;
      }
      @include respond-max($bp-mob) {
        height: 300px;
        width: 212px;

        &.aw-poster,
        &.ii-poster,
        &.ss-poster,
        &.bw-poster,
        &.ns-poster {
          top: 50%;
        }
      }

      @include respond-max-height($bp-mob-xs) {
        &.hw-poster {
          top: 57%;
        }
      }

      @include respond-max-height($bp-mob-xxs) {
        &.aw-poster,
        &.ii-poster,
        &.ss-poster,
        &.bw-poster,
        &.ns-poster {
          top: 56%;
        }

        &.hw-poster {
          top: 64%;
        }
      }

      &.aw-poster.inactive-card {
        top: -45%;
      }
      &.ii-poster.inactive-card {
        top: -34%;
      }
      &.ss-poster.inactive-card {
        left: 85%;
        top: 135%;
      }
      &.bw-poster.inactive-card {
        left: 77%;
        top: -33%;
      }
      &.hw-poster.inactive-card {
        left: 37%;
        top: 140%;
      }
      &.ns-poster.inactive-card {
        left: 28%;
        top: 150%;
      }

      @include respond-max-height($bp-mob-xxs) {
        height: 265px;
      }
    }

    &.aw {
      &-1 {
        transform: translate(-50%, -50%);
        left: 19%;
        top: 35%;
        height: 420px;

        @include respond-max($bp-lg-desk) {
          left: 21%;
          top: 34%;
          height: 308px;
        }
        @include respond-max($bp-desk) {
          height: 268px;
          left: 19%;
          top: 34%;
        }
        @include respond-max($bp-lg-lap) {
          height: 241px;
          left: 19%;
          top: 37%;
        }
        @include respond-max($bp-lap) {
          height: 192px;
          left: 21%;
          top: 34%;
        }
        @include respond-max($bp-tab) {
          height: 192px;
          left: 18%;
          top: 36%;
        }
        @include respond-max($bp-mob) {
          height: 158px;
          top: 265px;
          left: 59px;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 139px;
          top: 244px;
          left: 55px;
        }

        @include respond-max-height($bp-mob-xxs) {
          top: 265px;
        }

        &.inactive-card {
          left: 5%;
          top: -20%;
        }
      }

      &-2 {
        z-index: 2;
        left: 8.5%;
        top: 68%;
        height: 332px;

        @include respond-max($bp-lg-desk) {
          left: 11.5%;
          top: 68%;
          height: 253px;
        }
        @include respond-max($bp-desk) {
          height: 235px;
          left: 10.5%;
          top: 65%;
        }

        @include respond-max($bp-lg-lap) {
          height: 192px;
        }

        @include respond-max($bp-lap) {
          height: 158px;
          left: 13%;
        }

        @include respond-max($bp-tab) {
          left: 10.5%;
          top: 54%;
        }

        @include respond-max($bp-mob) {
          left: 35px;
          top: 435px;
          height: 133px;
        }

        @include respond-max-height($bp-mob-xs) {
          height: 117px;
          z-index: 5;
          top: 391px;
        }

        @include respond-max-height($bp-mob-xxs) {
          top: 368px;
        }

        &.inactive-card {
          left: -6%;
          top: -20%;
        }
      }

      &-3 {
        z-index: 0;
        left: 24.5%;
        bottom: -15%;
        height: 596px;

        @include respond-max($bp-lg-desk) {
          left: 27%;
          bottom: -16%;
          height: 462px;
        }
        @include respond-max($bp-desk) {
          height: 428px;
          left: 27%;
          bottom: -11%;
        }
        @include respond-max($bp-lg-lap) {
          height: 350px;
        }
        @include respond-max($bp-lap) {
          height: 288px;
          left: 28%;
          bottom: -11.5%;
        }
        @include respond-max($bp-tab) {
          left: 20%;
          bottom: 5%;
        }
        @include respond-max($bp-mob) {
          height: 196px;
          left: 90px;
          bottom: -2%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 172px;
          z-index: 4;
          bottom: 8%;
          left: 68px;
        }

        @include respond-max-height($bp-mob-xxs) {
          bottom: -7%;
        }

        &.inactive-card {
          left: 30%;
          bottom: -75%;
        }
      }

      &-4 {
        right: 0%;
        top: 33.5%;
        height: 355px;

        @include respond-max($bp-lg-desk) {
          height: 240px;
          right: 6%;
          top: 34%;
        }
        @include respond-max($bp-desk) {
          height: 240px;
          right: 1%;
          top: 36%;
        }
        @include respond-max($bp-lg-lap) {
          height: 197px;
          right: 2%;
          top: 36%;
        }
        @include respond-max($bp-lap) {
          height: 164px;
          right: 4%;
          top: 39%;
        }
        @include respond-max($bp-tab) {
          right: -21%;
          top: 35%;
        }
        @include respond-max($bp-mob) {
          right: -162px;
          top: 298px;
          height: 137px;
        }
        @include respond-max-height($bp-mob-xs) {
          top: 36%;
        }
        @include respond-max-height($bp-mob-xxs) {
          top: 39%;
        }

        &.inactive-card {
          left: 93%;
          top: -15%;
        }
      }

      &-5 {
        z-index: 2;
        right: 13%;
        bottom: -8%;
        height: 474px;

        @include respond-max($bp-lg-desk) {
          right: 13%;
          bottom: -12%;
          height: 339px;
        }
        @include respond-max($bp-desk) {
          height: 314px;
          right: 11.5%;
          bottom: -8%;
        }
        @include respond-max($bp-lg-lap) {
          height: 257px;
        }

        @include respond-max($bp-lap) {
          height: 219px;
          right: 15.5%;
          bottom: -6%;
        }
        @include respond-max($bp-tab) {
          right: 0%;
          bottom: 6%;
        }

        @include respond-max($bp-mob) {
          right: -51px;
          bottom: 6%;
          height: 156px;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 137px;
          z-index: 11;
          bottom: 14%;
          right: -43px;
        }

        @include respond-max-height($bp-mob-xxs) {
          bottom: 0;
        }

        &.inactive-card {
          bottom: -45%;
          right: -23%;
        }
      }

      &-6 {
        z-index: 0;
        bottom: 11%;
        right: -1%;
        height: 392px;

        @include respond-max($bp-lg-desk) {
          bottom: 12%;
          right: 0%;
          height: 270px;
        }
        @include respond-max($bp-desk) {
          height: 250px;
          right: -2%;
          bottom: 13%;
        }
        @include respond-max($bp-lg-lap) {
          height: 204px;
          bottom: 16%;
          right: -1%;
        }
        @include respond-max($bp-lap) {
          height: 169px;
          bottom: 12%;
          right: 5%;
        }

        @include respond-max($bp-tab) {
          bottom: 27%;
          right: -5%;
        }

        @include respond-max($bp-mob) {
          bottom: 24%;
          right: -20%;
          height: 138px;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 121px;
          z-index: 10;
          bottom: 28%;
        }
        @include respond-max-height($bp-mob-xxs) {
          bottom: 19%;
        }

        &.inactive-card {
          bottom: -48%;
          right: -20%;
        }
      }
    }

    &.ii {
      &-1 {
        left: 27.75%;
        top: 28%;
        height: 231px;

        @include respond-max($bp-lg-desk) {
          left: 28%;
          top: 29%;
          height: 200px;
        }
        @include respond-max($bp-desk) {
          height: 185px;
          left: 27%;
          top: 30%;
        }
        @include respond-max($bp-lg-lap) {
          height: 159px;
          top: 33%;
          left: 26%;
        }
        @include respond-max($bp-lap) {
          height: 133px;
          top: 36%;
          left: 27.5%;
        }
        @include respond-max($bp-tab) {
          height: 133px;
          top: 37%;
          left: 14.5%;
        }
        @include respond-max($bp-mob) {
          height: 101px;
          top: 32%;
          left: 20.5%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 88px;
          top: 29%;
          left: 22.5%;
        }
        @include respond-max-height($bp-mob-xxs) {
          top: 33%;
        }

        &.inactive-card {
          left: 26%;
          top: -25%;
        }
      }

      &-2 {
        left: 15%;
        top: 33%;
        height: 233px;

        @include respond-max($bp-lg-desk) {
          left: 13%;
          top: 36%;
          height: 210px;
        }
        @include respond-max($bp-desk) {
          height: 195px;
          top: 37%;
          left: 11%;
        }
        @include respond-max($bp-lg-lap) {
          height: 167px;
          top: 39%;
          left: 10%;
        }
        @include respond-max($bp-lap) {
          height: 141px;
          top: 40%;
          left: 11%;
        }
        @include respond-max($bp-tab) {
          height: 141px;
          top: 48%;
          left: 3%;
        }
        @include respond-max($bp-mob) {
          height: 108px;
          top: 43%;
          left: 3%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 93px;
          top: 34%;
        }

        &.inactive-card {
          left: -20%;
          top: 2%;
        }
      }

      &-3 {
        left: 73%;
        top: 57%;
        height: 296px;

        @include respond-max($bp-lg-desk) {
          left: 72%;
          top: 58%;
          height: 218px;
        }
        @include respond-max($bp-desk) {
          height: 202px;
          left: 74%;
        }
        @include respond-max($bp-lg-lap) {
          height: 174px;
        }
        @include respond-max($bp-lap) {
          height: 143px;
          left: 72%;
        }
        @include respond-max($bp-tab) {
          left: 78%;
          width: 194px;
          top: 57%;
        }
        @include respond-max($bp-mob) {
          height: 110.63px;
          width: 148px;
          right: -17%;
          top: 58%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 97px;
          width: 132px;
          left: 85%;
          top: 52%;
        }

        &.inactive-card {
          left: 86%;
          top: 112%;
        }
      }

      &-4 {
        right: 18%;
        top: 83%;
        height: 243px;

        @include respond-max($bp-lg-desk) {
          height: 194px;
          right: 18%;
          top: 83%;
        }
        @include respond-max($bp-desk) {
          height: 179px;
          top: 81%;
          right: 16%;
        }
        @include respond-max($bp-lg-lap) {
          height: 154px;
        }
        @include respond-max($bp-lap) {
          height: 127px;
          right: 19%;
          top: 83%;
        }
        @include respond-max($bp-tab) {
          height: 127px;
          top: 79%;
          right: 2%;
        }

        @include respond-max($bp-mob) {
          height: 97px;
          top: 72%;
          right: 0%;
        }

        @include respond-max-height($bp-mob-xs) {
          height: 85px;
          z-index: 5;
          top: 65%;
          right: -4%;
        }

        @include respond-max-height($bp-mob-xxs) {
          top: 73%;
        }

        &.inactive-card {
          right: 0%;
          top: 113%;
        }
      }

      &-5 {
        left: 31%;
        bottom: 38%;
        height: 213px;

        @include respond-max($bp-lg-desk) {
          left: 32%;
          bottom: 36%;
          height: 142.52px;
        }
        @include respond-max($bp-desk) {
          height: 132px;
          left: 32%;
        }
        @include respond-max($bp-lg-lap) {
          height: 114px;
          left: 31%;
          bottom: 34%;
        }
        @include respond-max($bp-lap) {
          height: 96px;
          left: 32%;
          bottom: 31%;
        }
        @include respond-max($bp-tab) {
          height: 96px;
          left: 22%;
          bottom: 34%;
        }
        @include respond-max($bp-mob) {
          height: 73px;
          left: 16%;
          bottom: 36%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 63px;
          bottom: 45%;
        }

        &.inactive-card {
          bottom: 103%;
        }
      }

      &-6 {
        bottom: 4%;
        left: 17%;
        height: 255px;

        @include respond-max($bp-lg-desk) {
          bottom: -1%;
          left: 19%;
          height: 190px;
        }
        @include respond-max($bp-desk) {
          height: 176px;
        }

        @include respond-max($bp-lg-lap) {
          height: 151px;
        }

        @include respond-max($bp-lap) {
          height: 128px;
        }

        @include respond-max($bp-tab) {
          height: 128px;
          left: 6%;
          bottom: 8%;
        }

        @include respond-max($bp-mob) {
          height: 98px;
          bottom: 7%;
        }

        @include respond-max-height($bp-mob-xs) {
          height: 86px;
          bottom: 18%;
          left: 9%;
        }

        &.inactive-card {
          bottom: -31%;
          left: -8%;
        }
      }

      &-7 {
        left: 17%;
        top: 53%;
        height: 212px;

        @include respond-max($bp-lg-desk) {
          left: 17%;
          top: 59%;
          height: 174px;
        }
        @include respond-max($bp-desk) {
          height: 161px;
        }
        @include respond-max($bp-lg-lap) {
          height: 138px;
          top: 60%;
          left: 14%;
        }
        @include respond-max($bp-lap) {
          height: 117px;
          top: 61%;
          left: 17%;
        }
        @include respond-max($bp-tab) {
          height: 117px;
          left: 0%;
          top: 64%;
        }
        @include respond-max($bp-mob) {
          height: 89px;
          left: 21px;
          top: 68%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 78px;
          top: 58%;
          left: 36px;
        }

        &.inactive-card {
          left: -30%;
        }
      }

      &-8 {
        left: 74%;
        top: 31%;
        height: 359px;

        @include respond-max($bp-lg-desk) {
          left: 72%;
          top: 31%;
          height: 258px;
        }
        @include respond-max($bp-desk) {
          height: 238px;
          left: 74%;
          top: 31%;
        }
        @include respond-max($bp-lg-lap) {
          height: 205px;
          left: 75%;
          top: 33%;
        }
        @include respond-max($bp-lap) {
          height: 168px;
          left: 72%;
          top: 34%;
        }
        @include respond-max($bp-tab) {
          height: 168px;
          left: 82%;
          top: 37%;
        }
        @include respond-max($bp-mob) {
          height: 131.03px;
          width: 99px;
          left: 77%;
          top: 260px;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 115px;
          width: 87px;
          top: 256px;
        }

        @include respond-max-height($bp-mob-xxs) {
          top: 218px;
        }

        &.inactive-card {
          left: 73%;
          top: -14%;
        }
      }

      &-9 {
        right: 0%;
        bottom: 5%;
        height: 240px;

        @include respond-max($bp-lg-desk) {
          right: 1%;
          bottom: 6%;
          height: 167px;
        }
        @include respond-max($bp-desk) {
          height: 154px;
          right: -2%;
          bottom: 8%;
        }
        @include respond-max($bp-lg-lap) {
          height: 132px;
          right: -1%;
        }
        @include respond-max($bp-lap) {
          height: 109px;
          right: 3%;
          bottom: 6%;
        }
        @include respond-max($bp-tab) {
          height: 109px;
          bottom: 20%;
          right: -16%;
        }
        @include respond-max($bp-mob) {
          height: 84px;
          bottom: 50px;
          right: -27%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 74px;
          right: -22%;
          bottom: 120px;
        }
        @include respond-max-height($bp-mob-xxs) {
          bottom: 20px;
        }

        &.inactive-card {
          right: -56%;
        }
      }

      &-10 {
        top: 42%;
        right: 4%;
        height: 278px;

        @include respond-max($bp-lg-desk) {
          top: 42%;
          right: 6%;
          height: 194px;
        }
        @include respond-max($bp-desk) {
          height: 179px;
          top: 45%;
          right: 4%;
        }
        @include respond-max($bp-lg-lap) {
          height: 154px;
        }
        @include respond-max($bp-lap) {
          height: 127px;
          top: 51%;
          right: 7%;
        }
        @include respond-max($bp-tab) {
          height: 127px;
          top: 47%;
          right: -11%;
        }
        @include respond-max($bp-mob) {
          height: 96px;
          top: 42%;
          right: -13%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 84px;
          top: 39%;
          z-index: 6;
          right: -10%;
        }
        @include respond-max-height($bp-mob-xxs) {
          top: 43%;
        }

        &.inactive-card {
          right: -36%;
        }
      }

      &-11 {
        bottom: 4%;
        left: 29%;
        height: 356px;

        @include respond-max($bp-lg-desk) {
          bottom: 1%;
          left: 30%;
          height: 254px;
        }
        @include respond-max($bp-desk) {
          height: 235px;
          left: 30%;
          bottom: 1%;
        }
        @include respond-max($bp-lg-lap) {
          height: 202px;
          bottom: 2%;
        }
        @include respond-max($bp-lap) {
          height: 171px;
          bottom: -2%;
          left: 31%;
        }
        @include respond-max($bp-tab) {
          height: 171px;
          bottom: 10%;
          left: 24%;
        }
        @include respond-max($bp-mob) {
          height: 130px;
          bottom: 10px;
          left: 40%;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 113px;
          bottom: 80px;
        }
        @include respond-max-height($bp-mob-xxs) {
          bottom: -20px;
        }

        &.inactive-card {
          bottom: -45%;
        }
      }
    }

    &.ss {
      &-1 {
        right: 2%;
        top: 60%;
        height: 516px;

        @include respond-max($bp-lg-desk) {
          right: 2%;
          top: 59%;
          height: 373px;
        }
        @include respond-max($bp-desk) {
          height: 345px;
        }
        @include respond-max($bp-lg-lap) {
          height: 297px;
        }
        @include respond-max($bp-lap) {
          height: 250px;
          right: 2%;
          top: 65%;
        }
        @include respond-max($bp-tab) {
          height: 250px;
          right: -24%;
          top: 58%;
        }
        @include respond-max($bp-mob) {
          height: 172px;
          right: -39%;
          top: 62%;
        }
        @include respond-max-height($bp-mob-xs) {
          top: 52%;
        }

        &.inactive-card {
          right: -76%;
          top: -20%;
        }
      }

      &-2 {
        left: 22.5%;
        top: 35%;
        height: 440px;

        @include respond-max($bp-lg-desk) {
          left: 22.5%;
          top: 36%;
          height: 343px;
        }
        @include respond-max($bp-desk) {
          height: 318px;
        }
        @include respond-max($bp-lg-lap) {
          height: 273px;
        }
        @include respond-max($bp-lap) {
          height: 230px;
          left: 23%;
          top: 42%;
        }
        @include respond-max($bp-tab) {
          height: 230px;
          left: 10%;
          top: 43%;
        }
        @include respond-max($bp-mob) {
          height: 159px;
          left: 9%;
          top: 40%;
        }
        @include respond-max-height($bp-mob-xs) {
          top: 36.5%;
        }

        &.inactive-card {
          left: -12%;
          top: -14%;
        }
      }

      &-3 {
        left: 27%;
        top: 79%;
        height: 330px;

        @include respond-max($bp-lg-desk) {
          left: 28%;
          top: 79%;
          height: 222px;
        }
        @include respond-max($bp-desk) {
          height: 205px;
        }
        @include respond-max($bp-lg-lap) {
          height: 176px;
        }
        @include respond-max($bp-lap) {
          height: 148px;
        }
        @include respond-max($bp-tab) {
          height: 148px;
          left: 17%;
          top: 72%;
        }
        @include respond-max($bp-mob) {
          height: 123px;
          left: 19%;
          top: 77%;
        }
        @include respond-max-height($bp-mob-xs) {
          top: 67%;
        }

        &.inactive-card {
          top: 114%;
        }
      }
    }

    &.bw {
      &-1 {
        right: 19%;
        top: 44%;
        height: 335px;

        @include respond-max($bp-lg-desk) {
          right: 19%;
          top: 47%;
          height: 265px;
        }
        @include respond-max($bp-desk) {
          height: 245px;
        }

        @include respond-max($bp-lg-lap) {
          height: 211px;
          right: 18%;
        }

        @include respond-max($bp-lap) {
          height: 178px;
          top: 50%;
        }

        @include respond-max($bp-tab) {
          height: 178px;
          top: 55%;
          right: 5%;
        }

        @include respond-max($bp-mob) {
          height: 123px;
          top: 56%;
          right: -10%;
        }

        @include respond-max-height($bp-mob-xs) {
          top: 53%;
        }

        @include respond-max-height($bp-mob-xxs) {
          top: 60%;
          right: -15%;
        }

        &.inactive-card {
          right: -46%;
        }
      }

      &-2 {
        left: 22%;
        top: 74%;
        height: 336px;

        @include respond-max($bp-lg-desk) {
          left: 21%;
          top: 77%;
          height: 285px;
        }
        @include respond-max($bp-desk) {
          height: 265px;
        }
        @include respond-max($bp-lg-lap) {
          height: 227px;
          top: 74%;
        }
        @include respond-max($bp-lap) {
          height: 192px;
        }
        @include respond-max($bp-tab) {
          height: 192px;
          left: 17%;
        }
        @include respond-max($bp-mob) {
          height: 132px;
          top: 75%;
          left: 7%;
        }
        @include respond-max-height($bp-mob-xs) {
          top: 67%;
        }

        &.inactive-card {
          left: -12%;
          top: 115%;
        }
      }

      &-3 {
        right: 1%;
        top: 36%;
        height: 443px;

        @include respond-max($bp-lg-desk) {
          right: 1.5%;
          top: 39%;
          height: 338px;
        }
        @include respond-max($bp-desk) {
          height: 313px;
        }

        @include respond-max($bp-lg-lap) {
          height: 269px;
        }

        @include respond-max($bp-lap) {
          height: 226px;
        }

        @include respond-max($bp-tab) {
          height: 226px;
          right: -12%;
        }

        @include respond-max($bp-mob) {
          height: 156px;
          top: 36%;
          right: -17%;
        }
        @include respond-max-height($bp-mob-xs) {
          top: 32%;
        }

        &.inactive-card {
          right: -50%;
          top: -15%;
        }
      }

      &-4 {
        left: 23%;
        top: 40%;
        height: 465px;

        @include respond-max($bp-lg-desk) {
          left: 23%;
          top: 37%;
          height: 372px;
        }
        @include respond-max($bp-desk) {
          height: 344px;
        }

        @include respond-max($bp-lg-lap) {
          height: 296px;
        }

        @include respond-max($bp-lap) {
          height: 249px;
        }

        @include respond-max($bp-tab) {
          height: 249px;
          left: 11%;
          top: 43%;
        }

        @include respond-max($bp-mob) {
          height: 172px;
          left: 14%;
          top: 44%;
        }

        @include respond-max-height($bp-mob-xs) {
          top: 36%;

        }

        &.inactive-card {
          left: -25%;
          top: -20%;
        }
      }

      &-5 {
        right: 2%;
        bottom: 1%;
        height: 366px;

        @include respond-max($bp-lg-desk) {
          right: 1%;
          bottom: -4%;
          height: 288px;
        }
        @include respond-max($bp-desk) {
          height: 266px;
        }

        @include respond-max($bp-lg-lap) {
          height: 221px;
          bottom: 2%;
        }

        @include respond-max($bp-lap) {
          height: 193px;
          bottom: -5%;
        }

        @include respond-max($bp-tab) {
          height: 193px;
          bottom: 5%;
          right: -28%;
        }

        @include respond-max($bp-mob) {
          height: 133px;
          bottom: 5%;
          right: -19%;
        }

        @include respond-max-height($bp-mob-xs) {
          bottom: 10%;
        }

        &.inactive-card {
          right: -71%;
          bottom: -45%;
        }
      }
    }

    &.hw {
      &-1 {
        right: 16%;
        top: 34%;
        height: 402px;

        @include respond-max($bp-lg-desk) {
          right: 17%;
          top: 35%;
          height: 300px;
        }

        @include respond-max($bp-desk) {
          height: 278px;
        }

        @include respond-max($bp-lg-lap) {
          height: 240px;
        }

        @include respond-max($bp-lap) {
          height: 201px;
        }

        @include respond-max($bp-tab) {
          height: 201px;
          right: -2%;
        }

        @include respond-max($bp-mob) {
          height: 141px;
          top: 38%;
          right: -28px;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 129px;
          top: 35%;
        }
        @include respond-max-height($bp-mob-xxs) {
          right: -11px;
        }

        &.inactive-card {
          right: 30%;
          top: -20%;
        }
      }

      &-2 {
        left: 21%;
        top: 41%;
        height: 377px;

        @include respond-max($bp-lg-desk) {
          left: 20%;
          top: 37%;
          height: 310px;
        }

        @include respond-max($bp-desk) {
          height: 287px;
        }

        @include respond-max($bp-lg-lap) {
          height: 247px;
        }

        @include respond-max($bp-lap) {
          height: 208px;
        }

        @include respond-max($bp-tab) {
          height: 208px;
        }

        @include respond-max($bp-mob) {
          height: 144px;
          top: 350px;
          left: 100px;
        }
        @include respond-max-height($bp-mob-xs) {
          height: 130px;
          top: 314px;
          left: 63px;
        }
        @include respond-max-height($bp-mob-xxs) {
          top: 316px;
        }

        &.inactive-card {
          left: 2%;
          top: -30%;
        }
      }

      &-3 {
        right: 17%;
        top: 79%;
        height: 415px;

        @include respond-max($bp-lg-desk) {
          height: 304px;
          right: 19%;
          top: 73%;
        }

        @include respond-max($bp-desk) {
          height: 282px;
        }

        @include respond-max($bp-lg-lap) {
          height: 243px;
          right: 16%;
        }

        @include respond-max($bp-lap) {
          height: 205px;
          right: 18%;
        }

        @include respond-max($bp-tab) {
          height: 205px;
          right: 3%;
          top: 80%;
        }

        @include respond-max($bp-mob) {
          height: 142px;
          top: 76%;
          right: -35px;
          z-index: 10;
        }

        @include respond-max-height($bp-mob-xs) {
          height: 127px;
          top: 67.5%;
          right: -19px;
        }
        @include respond-max-height($bp-mob-xxs) {
          top: 80%;
        }

        &.inactive-card {
          right: 10%;
          top: 120%;
        }
      }

      &-4 {
        right: -4%;
        top: 55%;
        height: 528px;

        @include respond-max($bp-lg-desk) {
          right: -2%;
          top: 55%;
          height: 376px;
        }
        @include respond-max($bp-desk) {
          height: 348px;
        }

        @include respond-max($bp-lg-lap) {
          height: 294px;
        }

        @include respond-max($bp-lap) {
          height: 253px;
        }

        @include respond-max($bp-tab) {
          height: 253px;
          right: -18%;
        }

        @include respond-max($bp-mob) {
          height: 175px;
          right: -120px;
        }

        @include respond-max-height($bp-mob-xs) {
          height: 157px;
          top: 50%;
          right: -106px;
        }

        &.inactive-card {
          right: -55%;
        }
      }

      &-5 {
        left: 27%;
        bottom: -5%;
        height: 444px;

        @include respond-max($bp-lg-desk) {
          left: 27%;
          bottom: -8%;
          height: 368px;
        }

        @include respond-max($bp-desk) {
          height: 340px;
        }

        @include respond-max($bp-lg-lap) {
          height: 293px;
        }

        @include respond-max($bp-lap) {
          height: 247px;
        }

        @include respond-max($bp-tab) {
          height: 247px;
          bottom: 4%;
          left: 14%;
        }

        @include respond-max($bp-mob) {
          height: 170px;
          bottom: 0%;
        }

        @include respond-max-height($bp-mob-xs) {
          height: 154px;
          bottom: 9.5%;
        }
        @include respond-max-height($bp-mob-xxs) {
          bottom: -2%;
        }

        &.inactive-card {
          left: -28%;
          bottom: 0%;
        }
      }
    }

    &.ns {
      &-1 {
        left: 25%;
        top: 76%;
        height: 311px;

        @include respond-max($bp-lg-desk) {
          left: 25%;
          top: 76%;
          height: 255px;
        }

        @include respond-max($bp-desk) {
          height: 236px;
        }

        @include respond-max($bp-lg-lap) {
          height: 203px;
          left: 24%;
        }

        @include respond-max($bp-lap) {
          height: 172px;
          left: 25%;
        }

        @include respond-max($bp-tab) {
          height: 172px;
          left: 16%;
        }

        @include respond-max($bp-mob) {
          height: 133px;
        }

        @include respond-max-height($bp-mob-xs) {
          top: 66%;
        }

        @include respond-max-height($bp-mob-xxs) {
          top: 75%;
        }

        &.inactive-card {
          left: -34%;
        }
      }

      &-2 {
        left: 20%;
        top: 45%;
        height: 406px;

        @include respond-max($bp-lg-desk) {
          height: 323px;
        }

        @include respond-max($bp-desk) {
          height: 298px;
        }

        @include respond-max($bp-lg-lap) {
          height: 256px;
        }

        @include respond-max($bp-lap) {
          height: 217px;
        }

        @include respond-max($bp-tab) {
          height: 217px;
          left: 8%;
          top: 49%;
        }

        @include respond-max($bp-mob) {
          height: 129px;
          top: 300px;
        }

        @include respond-max-height($bp-mob-xs) {
          top: 262px;
        }

        &.inactive-card {
          left: -32%;
        }
      }

      &-3 {
        right: 10%;
        top: 40%;
        height: 590px;

        @include respond-max($bp-lg-desk) {
          height: 453px;
        }

        @include respond-max($bp-desk) {
          height: 420px;
        }

        @include respond-max($bp-lg-lap) {
          height: 361px;
        }

        @include respond-max($bp-lap) {
          height: 305px;
        }

        @include respond-max($bp-tab) {
          height: 305px;
          top: 48%;
          right: -18%;
        }

        @include respond-max($bp-mob) {
          height: 191px;
          right: -50px;
          top: 350px;
        }

        @include respond-max-height($bp-mob-xs) {
          top: 318px;
        }

        &.inactive-card {
          right: 5%;
          top: -24%;
        }
      }

      &-4 {
        right: 4%;
        top: 75%;
        height: 276px;

        @include respond-max($bp-lg-desk) {
          right: 4%;
          top: 80%;
          height: 216px;
        }

        @include respond-max($bp-desk) {
          height: 199px;
        }

        @include respond-max($bp-lg-lap) {
          height: 171px;
        }

        @include respond-max($bp-lap) {
          height: 145px;
        }

        @include respond-max($bp-tab) {
          height: 145px;
          right: -16%;
          top: 79%;
        }

        @include respond-max($bp-mob) {
          height: 120px;
          right: -150px;
        }

        @include respond-max-height($bp-mob-xs) {
          top: 66%;
        }

        &.inactive-card {
          right: -16%;
          top: 120%;
        }
      }
    }
  }
}
