#outro-scene-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mail-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 54px 0 29px 0;
  font-family: "Helvetica Roman";
  font-weight: 700;
  letter-spacing: 0;

  @include respond-max($bp-lg-lap) {
    margin: 44px 0 22px 0;
  }

  @include respond-max($bp-lap) {
    margin: 28px 0 0 0;
  }

  @include respond-max($bp-mob) {
    margin: 28px 0 20px 0;
  }

  @include respond-max-height($bp-mob-xs) {
    margin: 12px 0 20px 0;
  }

  form {
    margin-bottom: 1%;
  }

  input {
    padding: 15px 12px 15px 20px;
    border-radius: 100px;
    border: 0px;
    width: 480px;
    text-transform: uppercase;
    font-size: 14px;

    @include respond-max($bp-lg-desk) {
      padding: 11px 12px 11px 20px;
      font-size: 12px;
      width: 350px;
    }
    @include respond-max($bp-tab) {
      font-size: 10px;
    }
    @include respond-max($bp-mob) {
      width: 240px;
    }
  }

  button {
    padding: 15px;
    background-color: #ff4c24;
    color: #ffffff;
    margin-left: -16%;
    border-radius: 50px;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;

    @include respond-max($bp-lg-desk) {
      padding: 11px 15px 11px 15px;
      font-size: 12px;
    }
    @include respond-max($bp-tab) {
      font-size: 10px;
    }
  }

  .msg-alert {
    font-family: "Helvetica Roman", sans-serif;
    font-size: 11px;
    line-height: 100%;
    text-transform: uppercase;
    display: block;

    p {
      color: #5e7017 !important;
    }
  }

  .form-subscribe {
    position: relative;
    width: 550px;
    padding-bottom: 29px;
  }
}

.social-bar {
  display: block;
  z-index: 1;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
  gap: 24px;
  font-family: "Helvetica";
  font-weight: 700;
  letter-spacing: 0;
  color: #5e7017;
  list-style: none;
  margin: 12px;

  a,
  a:link {
    color: #5e7017;
    text-decoration: none;
    transition: 0.5s;
    font-size: 36px;

    &:hover {
      color: #ff4c24;
      cursor: pointer;
    }

    @include respond-max($bp-lg-desk) {
      font-size: 18px;
    }

    @include respond-max($bp-desk) {
      font-size: 14px;
    }
  }
}
