.top-gradient {
  background: rgb(221, 220, 255);
  background: linear-gradient(
    180deg,
    rgba(221, 220, 255, 1) 0%,
    rgba(250, 251, 251, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 219px;
  z-index: 0;

  @include respond-max($bp-lg-desk) {
    height: 76px;
  }
  @include respond-max($bp-desk) {
    height: 76px;
  }

  @include respond-max($bp-lg-lap) {
    height: 76px;
  }
  @include respond-max($bp-lap) {
    height: 76px;
  }

  @include respond-max($bp-tab) {
    height: 152px;
  }
  @include respond-max($bp-mob) {
    height: 152px;
  }
}
