@use "sass:math";

// breakpoints
$bp-mac-desk: 2560px;
$bp-lg-desk: 2050px;
$bp-desk: 1680px;
$bp-lg-lap: 1440px;
$bp-lap: 1280px;
$bp-tab: 820px;
$bp-mob: 600px;
$bp-mob-xs: 670px; // iPhone XR users
$bp-mob-xxs: 625px; // iPhone SE users

@mixin respond($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin respond-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin respond-max-height($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}

@function px-calc($size) {
  $pxSize: $size * 1.333333333;
  @return #{$pxSize}px;
}

@include respond($bp-lg-desk) {
  .mobile-only {
    display: inline-block !important;
  }
}

@include respond($bp-tab) {
  .mobile-only {
    display: block !important;
    margin-top: -5px;
    margin-bottom: 5px;

    .space {
      display: none;
    }
  }
}

@include respond($bp-desk) {
  .ss-mobile-only {
    display: block !important;
    .space {
      display: none;
    }
  }
}
