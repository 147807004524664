@font-face {
  font-family: "NSC-BigCaslon";
  src: url("../assets/fonts/BigCaslon.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Helvetica Roman";
  src: url("../assets/fonts/HelveticaLTStd-Roman.otf");
  font-weight: 200;
}

$font-serif: "NSC-BigCaslon", Times, serif;
$font-sans: Helvetica, Arial, sans-serif;
