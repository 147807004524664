.nav-row {
  &__btn {
    bottom: 8%;
    position: absolute;

    @include respond-max($bp-mob) {
      bottom: 12px;
      position: fixed;
    }

    a {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      overflow: hidden;

      svg {
        display: inline-block;
        width: 200px;
        height: 100%;

        @include respond-max($bp-lg-desk) {
          width: 150px;
        }

        @include respond-max($bp-lg-lap) {
          width: 110px;
        }

        @include respond-max($bp-mob) {
          width: 95px;
        }
      }
    }

    &.back-btn {
      left: 65px;

      @include respond-max($bp-desk) {
        left: 50px;
      }
      @include respond-max($bp-lg-lap) {
        left: 55px;
      }
      @include respond-max($bp-lap) {
        left: 33px;
      }
      @include respond-max($bp-mob) {
        left: 20px;
      }
    }

    &.forward-btn {
      transform: translateX(0%);
      right: 65px;

      @include respond-max($bp-desk) {
        right: 50px;
      }

      @include respond-max($bp-lg-lap) {
        right: 55px;
      }
      @include respond-max($bp-lap) {
        right: 33px;
      }

      @include respond-max($bp-mob) {
        right: 20px;
      }
    }

    &.start-btn {
      transform: translateX(0%);
      right: 65px;

      @include respond-max($bp-desk) {
        right: 50px;
      }

      @include respond-max($bp-lg-lap) {
        right: 55px;
      }
      @include respond-max($bp-lap) {
        right: 33px;
      }

      @include respond-max($bp-tab) {
        width: 100%;
        display: flex;
        justify-content: center;
        right: 0;
      }
    }

    &.asian-womxn {
      svg path.btn-stroke {
        stroke: #ff4c24;
      }

      svg path.btn-fill {
        fill: #ff4c24;
      }
    }

    &.illustrators {
      svg path.btn-stroke {
        stroke: #ffceb3;
      }

      svg path.btn-fill {
        fill: #ffceb3;
      }
    }

    &.spiritual {
      svg path.btn-stroke {
        stroke: #0b8639;
      }

      svg path.btn-fill {
        fill: #0b8639;
      }
    }

    &.blog {
      svg path.btn-stroke {
        stroke: #c8ffce;
      }

      svg path.btn-fill {
        fill: #c8ffce;
      }
    }

    &.health {
      svg path.btn-stroke {
        stroke: #34ac5d;
      }

      svg path.btn-fill {
        fill: #34ac5d;
      }
    }

    &.snack-attack {
      svg path.btn-stroke {
        stroke: #8667ff;
      }

      svg path.btn-fill {
        fill: #8667ff;
      }
    }
  }

  &.begin-again-btn svg {
    bottom: 8%;
    position: absolute;
    width: 200px;
    right: 65px;

    @include respond-max($bp-lg-desk) {
      right: 55px;
      width: 183px;
    }

    @include respond-max($bp-desk) {
      right: 50px;
    }

    @include respond-max($bp-lg-lap) {
      width: 137px;
    }

    @include respond-max($bp-lap) {
      right: 33px;
    }

    @include respond-max($bp-tab) {
      bottom: 8%;
    }

    @include respond-max($bp-mob) {
      position: fixed;
      width: 114px;
      right: 20px;
    }

    @include respond-max($bp-mob-xs) {
      bottom: 12px;
      position: fixed;
    }
  }
}
