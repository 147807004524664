@import "./fonts";
@import "./mixins";
@import "./navigation";
@import "./image-well";
@import "./thanks-page";
@import "./opening-sequence";

// general body styles
body {
  margin: 0;
  font-family: "Helvetica Roman";
}

main {
  position: relative;
  overflow: hidden;

  &.body-index {
    background-color: #ffd9b7;
  }

  &.body-asian-womxn {
    background-color: #fff0dc;
  }

  &.body-illustrators {
    background-color: #0b1676;
  }

  &.body-spiritual {
    background-color: #ddd2f5;
  }

  &.body-blog {
    background-color: #0a4d4d;
  }

  &.body-health {
    background-color: #ffe2b7;
  }

  &.body-nightsnack {
    background-color: #c6d2cf;
  }

  &.body-thanks {
    background-color: #ffe3c9;
  }
}

// general background styles
.background-container {
  width: 100vw;
  top: 0;
  position: fixed;
  z-index: 0;
}

// background segments
.background-container .bg-segment {
  height: 33.33vh;
  width: 0%;
  max-width: 100%;

  &.index {
    background-color: #ffe3c9;
  }

  &.asian-womxn {
    background-color: #fff1de;
  }

  &.illustrators {
    background-color: #0a1772;
  }

  &.spiritual {
    background-color: #ddd3f3;
  }

  &.blog {
    background-color: #0f4d4d;
  }

  &.health {
    background-color: #fee3ba;
  }

  &.nightsnack-club {
    background-color: #c6d2cf;
  }

  &.thanks {
    background-color: #ffe3c9;
  }
}

.scene-wrapper {
  height: 100vh;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);

  &.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.outro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// large page titles
.page-title-container {
  display: block;
  position: relative;
  overflow: hidden;

  &.asian-title,
  &.illustrators-title,
  &.spiritual-title,
  &.blog-title,
  &.nightsnack-title,
  &.health-title {
    display: inline-block;

    @include respond-max($bp-tab) {
      display: inline-block;
    }
  }

  &.nightsnack-title {
    &.mobile-only {
      @include respond-max($bp-tab) {
        display: inline-block;
        margin-left: -8px;
      }
    }
  }
}

.page-title {
  font-family: $font-sans;
  font-size: 12.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 0.95;
  text-align: center;
  display: inline-block;
  margin: 0;
  position: relative;
  letter-spacing: -0.035em;

  // @include respond-max($bp-lg-desk) {
  //   font-size: 195px;
  // }

  // @include respond-max($bp-lg-lap) {
  //   font-size: 165px;
  // }

  // @include respond-max($bp-lap) {
  //   font-size: 142px;
  // }

  @include respond-max($bp-mob) {
    font-size: 64px;
  }
}

.scene-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include respond-max($bp-mob) {
    margin-top: -155px;
  }
}

.anim-container {
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;

  &.outro {
    @include respond-max($bp-mob) {
      margin-top: -105px;
    }

    @include respond-max-height($bp-mob-xxs) {
      margin-top: -120px;
    }
  }

  &.page-container-spacing {
    padding-top: 85px;
    padding-bottom: 15px;

    @include respond-max($bp-lg-desk) {
      padding-top: 51px;
      padding-bottom: 14px;
    }

    @include respond-max($bp-lg-lap) {
      padding-bottom: 5px;
    }

    @include respond-max($bp-tab) {
      padding-top: 5%;
      padding-bottom: 25px;
    }

    @include respond-max($bp-mob) {
      padding-top: 28px;
      padding-bottom: 2px;
    }
  }

  .anim-title {
    position: relative;
    display: inline-block;
    margin: 0;
    margin: 0px 0 9px;

    @include respond-max($bp-tab) {
      margin: 0px 0 15px;
    }

    @include respond-max($bp-desk) {
      margin: 0px 0 21px;
    }

    &.current-title {
      margin-bottom: 0;
    }
  }

  thanks-title {
    position: relative;
    display: block;
    margin: 0;
    line-height: 1;
  }

  .anim-subtitle {
    display: inline-block;
    position: relative;
  }

  .nsc {
    &-title-small {
      font-size: 3.1vw;
      font-weight: 200;
      text-align: center;
      padding-bottom: 15px;

      @include respond-max($bp-mob) {
        font-size: 32px;
      }
    }

    &-title-medium {
      font-family: $font-serif;
      font-size: 4vw;
      font-weight: 200;
      line-height: 1.25;
      vertical-align: middle;
      letter-spacing: -0.72px;
      color: #5e7017;
      text-align: center;
      margin: 0;

      // @include respond-max($bp-lg-desk) {
      //   font-size: 102px;
      // }

      // @include respond-max($bp-lg-lap) {
      //   font-size: 81px;
      // }

      // @include respond-max($bp-lap) {
      //   font-size: 69px;
      // }

      // @include respond-max($bp-tab) {
      //   letter-spacing: -1.38;
      // }

      @include respond-max($bp-mob) {
        font-size: 36px;
        letter-spacing: -1.38;
      }
    }

    &-title-large {
      font-size: 15.2vw;
      line-height: 1;
      letter-spacing: -0.75vw;

      // @include respond-max($bp-lg-desk) {
      //   font-size: 262px;
      //   line-height: 80%;
      //   letter-spacing: -4px;
      // }

      // @include respond-max($bp-desk) {
      //   font-size: 262px;
      // }

      // @include respond-max($bp-lg-lap) {
      //   font-size: 185px;
      //   letter-spacing: -7.4px;
      // }

      // @include respond-max($bp-lap) {
      //   line-height: 85%;
      // }

      // @include respond-max($bp-tab) {
      //   font-size: 138px;
      // }

      @include respond-max($bp-mob) {
        font-size: 70px;
        letter-spacing: -0.04em;
        line-height: 63px;
      }
    }
  }


  .nsc-title-kerning {
    margin-right: 14px;

    @include respond-max($bp-tab) {
      margin-right: 10px;
    }

    @include respond-max($bp-mob) {
      margin-right: 8px;
    }
  }
}

.intro-container-spacing {
  padding-top: 2%;

  @include respond-max($bp-lg-desk) {
    padding-top: 5%;
  }

  @include respond-max($bp-tab) {
    padding-top: 30%;
  }

  @include respond-max($bp-mob) {
    padding-top: 50%;
  }
}

.previous-title {
  display: inline-block;
  position: absolute;
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
  left: 50%;
  top: 0;
  margin: 0;
  font-weight: 200;
  font-size: 3.1vw;

  // @include respond-max($bp-lg-desk) {
  //   font-size: 60px;
  // }

  // @include respond-max($bp-lg-lap) {
  //   font-size: 52px;
  // }

  // @include respond-max($bp-lap) {
  //   font-size: 46px;
  // }

  @include respond-max($bp-mob) {
    font-size: 32px;
  }
}

.opening-title {
  color: #5e7017;
}

.asian-title {
  color: #f73b35;
}

.illustrators-title {
  color: #ffceb3;
}

.spiritual-title {
  color: #0b8639;
}

.blog-title {
  color: #c8ffce;
}

.health-title {
  color: #34ac5d;
}

.nightsnack-title {
  color: #8667ff;
}

.thanks-title {
  color: #5e7017;
}

.nsc-page-title {
  font-family: $font-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

#nsc-anim-wipe {
  width: 0%;
  clip-path: inset(0 0 0 0);
  min-height: 105px;

  @include respond-max($bp-lg-desk) {
    min-height: 85px;
  }

  @include respond-max($bp-lap) {
    min-height: 70px;
  }

  @include respond-max($bp-tab) {
    min-height: 55px;
  }

  .current-title {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      cursor: pointer;
    }
  }
}

.title-container {
  width: 100%;
  position: relative;
  z-index: 8;
}

.year-in-review {
  text-align: center;

  .thanks-cont {
    padding-top: 30px;

    @include respond-max($bp-desk) {
      padding-top: 15px;
    }

    @include respond-max($bp-lg-lap) {
      padding-top: 15px;
    }

    @include respond-max($bp-mob) {
      padding-top: 115px;
    }

    @include respond-max-height($bp-mob-xs) {
      padding-top: 120px;
    }
  }

  h3 {
    font-family: $font-sans;
    font-weight: 400;
    line-height: 85%;
    color: #5e7017;
    margin: 0;

    &.thanks-content {
      color: #5e7017;
      font-size: 9vw;
      letter-spacing: -0.04em;
      line-height: 90%;

      // @include respond-max($bp-lg-desk) {
      //   font-size: 170px;
      // }

      // @include respond-max($bp-lg-lap) {
      //   font-size: 136px;
      // }

      // @include respond-max($bp-lap) {
      //   font-size: 112px;
      // }

      @include respond-max($bp-mob) {
        font-size: 60px;
        line-height: 100%;
        letter-spacing: -0.04em;
      }

      &.content-desk {
        display: block;

        @include respond-max($bp-tab) {
          display: none;
        }
      }

      &.content-mobile {
        display: none;

        @include respond-max($bp-tab) {
          display: block;
        }
      }
    }
  }
}

.yir-cont {
  &:first-child {
    padding-bottom: 0;
  }

  &:last-child {
    padding-top: 0;

    @include respond-max($bp-lg-desk) {
      padding-top: 10px;
    }

    @include respond-max($bp-lap) {
      padding-top: 3px;
    }
  }
}

video {
  width: 80%;
}

.intro-video {
  text-align: center;
  margin: 10px 0 30px 0;
  width: 82%;

  @include respond-max($bp-lg-desk) {
    margin: 8px 0 25px 0;
  }

  @include respond-max($bp-desk) {
    width: 94%;
    margin: 10px 0 19px 0;
  }

  @include respond-max($bp-lg-lap) {
    margin: 5px 0 15px 0;
    width: 88%;
  }

  @include respond-max($bp-lap) {
    margin: 8px 0 11px 0;
    width: 78%;
  }

  @include respond-max($bp-tab) {
    margin: 10px 0 15px 0;
    width: 105%;
  }

  @include respond-max($bp-mob) {
    margin: 8px 22px 3px 22px;
  }
}
